<style lang="less" scoped>
  .purchase-form {
    display: flex;
    .form {
      flex: 1;
      padding-right: 20px;
    }
    .detail-table {
      flex: 1;
      min-height: 330px;
      .detail-table-t {
        height: calc(100% - 48px);
      }
    }
  }
</style>

<template>
  <div class="purchase-form">
    <div class="form">
      <fm-form :labelAlone="true" labelAlign="left">
      <fm-form-item required label="内容">
        <fm-input-new v-verifier type="textarea" v-model="value.content" placeholder="请输入验收内容"></fm-input-new>
      </fm-form-item>
      <fm-form-item label="验收日期">
        <fm-date-picker type="date" v-model="value.acceptanceTime" placeholder="请选择验收日期"></fm-date-picker>
      </fm-form-item>
      <fm-form-item label="使用部门">
        <fm-select  absolute filterable v-model="value.useOrgId">
          <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="采购部门">
        <fm-select  absolute filterable v-model="value.purchaseOrgId">
          <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="验收部门">
        <fm-select  absolute filterable v-model="value.acceptanceOrgId">
          <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>
    </div>
    <div class="detail-table">
      <fm-title title-text="相关物品" :title-menus="[{key: 'add', label: '新增'}]" @clickTitleMenu="clickTitleMenu"></fm-title>
      <div class="detail-table-t">
        <fm-table-new
          :auto-height="true"
          :columns="columns"
          border="row"
          :data-list="value.detailList"
          :stripe="false"
          size="small"
          emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <fm-form-dialog
      form-title="相关物品"
      :open-dialog.sync="openDialog"
      :form-parms="formParms"
      :old-data="chooseDetail"
      :mask-closable="false"
      label-alone
      label-align="left"
      form-width="600px"
      @formSubmit="formSubmit"
      submitBtnLabel="确定"
      @handleClose="openDialog = false">
    </fm-form-dialog>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import {
  dateOperating
} from '@/fmlib'

function getDefault () {
  return {
    content: '',
    useOrgId: null,
    purchaseOrgId: null,
    acceptanceOrgId: null,
    detailList: []
  }
}

export default {
  components: {
    TableActions
  },
  props: {
    data: { type: Object, defualt: () => null },
    purchaseList: { type: Array, defualt: () => [] }
  },
  data () {
    return {
      value: getDefault(),
      openDialog: false,
      chooseDetail: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.value = this.data ? Object.assign({}, this.data) : getDefault()
        this.value.detailList = this.value.detailList || []
      },
      immediate: true
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'update',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    orgList () {
      return this.$store.getters.orgList
    },
    formParms () {
      return [{
        type: 'select',
        label: '采购结果',
        key: 'purchaseId',
        selectDatas: this.purchaseList,
        check: {
          required: true
        }
      }]
    },
    columns () {
      return [{
        title: '物品名称',
        field: 'goodsName',
        render: (h, rowData) => {
          return h('div', rowData && rowData.purchase ? rowData.purchase.goodsName : '-')
        }
      },
      {
        title: '数量',
        field: 'num',
        render: (h, rowData) => {
          return h('div', rowData && rowData.purchase ? rowData.purchase.num : '-')
        }
      },
      {
        title: '总价值',
        field: 'total',
        render: (h, rowData) => {
          return h('div', rowData && rowData.purchase ? rowData.purchase.total : '-')
        }
      },
      {
        title: '操作',
        fixed: 'right',
        slot: 'actions'
      }]
    }
  },
  methods: {
    getData () {
      if (this.value && this.value.acceptanceTime && this.value.acceptanceTime instanceof Date) {
        this.value.acceptanceTime = dateOperating.computeDay({days: 0, date: this.value.acceptanceTime, format: 'yy-mm-dd hh:mm:ss'})
      }
      return this.value
    },
    async tableAction (parm) {
      this.chooseDetail = Object.assign({}, parm.data)
      if (parm.action === 'update') {
        this.openDialog = true
      } else {
        let del = await this.$dialog.confirm({title: '系统提示', content: '确定删除该物品吗?'})
        if (del) {
          this.$set(this.value, 'detailList', this.value.detailList.filter(v => v.id !== this.chooseDetail.id))
        }
      }
    },
    formSubmit (data, resolve) {
      let purchaseData = this.purchaseList.find(v => v.data.id === data.purchaseId)
      data.purchase = purchaseData.data || {}
      if (!data.id) {
        data.id = 'id_' + new Date().getTime()
        this.value.detailList.push(data)
      } else {
        Object.assign(this.value.detailList[this.value.detailList.findIndex(v => v.id === data.id)], data)
      }
      this.openDialog = false
      resolve()
    },
    clickTitleMenu () {
      this.chooseDetail = null
      this.openDialog = true
    }
  }
}
</script>
